import { isNaN, minBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../../../components/common/BrandComponent";
import ReferencedSection from "../../../components/common/ReferencedSection";
import { workflowActions } from "../../../redux/slices/workflow";
import {
  callInsuranceService,
  getCheapPackageFromList,
  getStepBadgeType,
} from "../../ConfigLoader";
import {
  useAllCurrentPackages,
  useCheapCurrentStep,
  useCheckPhotocromic,
  useTreatmentsMapper,
  useTransitionSelectedColor,
  usePriceStepItem,
} from "@hooks";
import { ParameterWithTitle } from "../../models/Parameter";
import { workflow } from "../../models/Workflow";
import { useTranslation } from "../../translations/TranslationHooks";
import useDelegatingLearnMore from "../../hooks/useDelegatingLearnMore";
import { useTranslate } from "../../translations/TranslationHooks";
import "./default.module.scss";
import GenericButton from "../../../components/common/GenericButton";
import { useConfigContent, useCurrentBrand } from "../../hooks";
import { useBundlesPrices, useCurrentType } from "../../hooks";

/**
 * This component displays the 'treatments' step. It uses the
 * ```StepComponentOptions``` to work in his flow.
 * on component loaded this component execute getTreaments method which create 2 array:
 * - first array is included treatments, if the current packages has the same value for treatments;
 * - second array is the avaiable treatments that user can add on lens filter
 */
export function Treatments() {
  const currentPackage = useCheapCurrentStep();
  const currentPackages = useAllCurrentPackages();
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);

  const [included, setIncluded] = useState(null);
  const [treatmentAvaiable, setTreatmentAvaiable] = useState(null);
  const [treatments, setTreatments] = useState(null);
  const dispatch = useDispatch();

  const loadTreatments = useTreatmentsMapper();
  const translate = useTranslation();
  const delegatingLearnMore = useDelegatingLearnMore();
  const delegateLearnMoreContent = useSelector(
    (state: any) => state.config?.actionsModule?.delegateLearnMoreContent
  );
  const learnMoreButton = useTranslate("steps.treatments.contentIdentifier");
  const moreInfoLabel = useTranslate("steps.treatments.learnMoreCTA");
  const setTransionColorImage = useTransitionSelectedColor();
  const isPhotocromic = useCheckPhotocromic();
  const enableDesignTypeStep = useSelector(
    (state: any) => state.config?.layoutSettings?.enableDesignTypeStep
  );
  const c = usePriceStepItem();
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const bundlesEnabled = useBundlesPrices();

  const frame = useSelector((state: any) => state.config?.data?.frame);

  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const currentType = useCurrentType();

  const configDesignType: any[] = useConfigContent("DesignType");
  const [designTypesAvaiable, setDesignTypesAvaiable] = useState(null);

  const brand = useCurrentBrand();

  useEffect(() => {
    if (currentPackages && configDesignType) {
      let designTypes = configDesignType.filter((c) =>
        currentPackages.validRXPackages.some(
          (cp) => cp.lensPackage.designType === c.id
        )
      );
      let designTypesGreyOut = configDesignType
        .filter((c) =>
          currentPackages.invalidRXPackages.some(
            (cp) => cp.lensPackage.designType === c.id
          )
        )
        .map((designType) => {
          return {
            ...designType,
            disabled: true,
          };
        });
      // .filter(designType =>
      //     !designTypes.find(d => d.id === designType.id)
      // );
      designTypes
        .concat(designTypesGreyOut)
        .sort((a, b) =>
          a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
        );

      setDesignTypesAvaiable(designTypes);
    }
  }, [configDesignType, currentPackages]);

  useEffect(() => {
    if (currentStep) {
      const tStep = workflow.findByStepKey("Treatments");
      if (tStep && currentStep.progressive <= tStep.progressive) {
        dispatch(workflowActions.setCurrentIncluded(null));
        dispatch(workflowActions.setParameterPrice(null));
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (
      currentPackage &&
      loadTreatments &&
      setTransionColorImage &&
      isPhotocromic != null
    ) {
      getTreatments();
    }
  }, [
    currentPackages,
    currentPackage,
    loadTreatments,
    setTransionColorImage,
    isPhotocromic,
  ]);

  const isInsuranceEnabled = useSelector(
    (state: any) => state.insurance.insuranceEnabled
  );

  const getTreatments = () => {
    const familyFilter = getFamilyFilter();
    if (familyFilter) {
      let forcedTreatments = [];
      if (isPhotocromic) forcedTreatments.push("transition");

      let filteredPackages = filterPackagesByFamily(
        currentPackages,
        familyFilter.value?.required,
        familyFilter.value?.excluded
      );
      let { included, treatments } = loadTreatments(
        filteredPackages,
        true,
        forcedTreatments
      );

      treatments.sort((a, b) => a.sequence - b.sequence);
      const sortedTreatments = [...treatments];

      setIncluded(included);
      dispatch(
        workflowActions.setCurrentIncluded(
          included.map((i) => ({ key: i.id, value: i.name }))
        )
      );
      const onlyFamilyTreatments = filterTreatmentsByFamily(
        treatments,
        familyFilter.value?.required,
        familyFilter.value?.additional
      ).sort((a, b) => a.sequence - b.sequence);

      const sortedOnlyFamilyTreatments = [...onlyFamilyTreatments];

      if (isInsuranceEnabled) {
        sortedTreatments.forEach((t) => {
          if (!t.insPrice || isNaN(t.insPrice)) {
            let filteredPackages = currentPackages.validRXPackages.filter(
              (p) => {
                return p.lensPackage.transition === t.id.split("$")[1];
              }
            );

            let cheapestPackages = null;
            if (filteredPackages.length > 1) {
              cheapestPackages = [getCheapPackageFromList(filteredPackages)];
            } else {
              cheapestPackages = filteredPackages;
            }

            let insurablePackages = cheapestPackages.filter(
              (pkg) => pkg.lensPackage.insurable
            );
            if (insurablePackages.length) {
              callInsuranceService(
                cheapestPackages,
                configInsurance,
                frame,
                dispatch
              );
            }
          }
        });
      }

      setTreatmentAvaiable(sortedOnlyFamilyTreatments);
      setTreatments(sortedTreatments);

      let minTreatmentPrice = minBy(onlyFamilyTreatments, (elem) => {
        if (isInsuranceEnabled) return elem.insPrice;
        return elem.price - elem.discount;
      });

      if (minTreatmentPrice) {
        let treatmentToSetInRedux = new ParameterWithTitle();
        const idTa = minTreatmentPrice.id.split("$")[0];
        const valueTa = minTreatmentPrice.id.split("$")[1];
        treatmentToSetInRedux.key = idTa;
        treatmentToSetInRedux.value = valueTa;
        treatmentToSetInRedux.title = minTreatmentPrice.title;
        treatmentToSetInRedux.price = minTreatmentPrice.price;
        treatmentToSetInRedux.discount = minTreatmentPrice.discount;
        treatmentToSetInRedux.insPrice = minTreatmentPrice.insPrice;

        dispatch(workflowActions.setParameterPrice([treatmentToSetInRedux]));
      }
    } else {
      let { included, treatments } = loadTreatments(currentPackages);
      setIncluded(included);

      dispatch(
        workflowActions.setCurrentIncluded(
          included.map((i) => ({ key: i.id, value: i.name }))
        )
      );
      setTreatmentAvaiable(treatments);
      setTreatments(treatments);
    }
  };

  const getFamilyFilter = () => {
    return workflow.currentStep.params.find((p) => p.key === "familyFilter");
  };

  const filterPackagesByFamily = (
    packages: { validRXPackages: any[]; invalidRXPackages: any[] },
    requiredoptions: string[],
    excludedoptions?: string[]
  ) => {
    let filteredPackages = packages.validRXPackages;
    let greyOutFilteredPackages = packages.invalidRXPackages;

    if (requiredoptions?.length) {
      filteredPackages = filteredPackages.filter((cp) => {
        let ret = true;
        requiredoptions.forEach(
          (t) => (ret = ret && cp.lensPackage[t] != null)
        );
        if (excludedoptions?.length)
          excludedoptions.forEach(
            (t) => (ret = ret && cp.lensPackage[t] === null)
          );
        return ret;
      });

      greyOutFilteredPackages = greyOutFilteredPackages.filter((cp) => {
        let ret = true;
        requiredoptions.forEach(
          (t) => (ret = ret && cp.lensPackage[t] != null)
        );
        if (excludedoptions?.length)
          excludedoptions.forEach(
            (t) => (ret = ret && cp.lensPackage[t] === null)
          );
        return ret;
      });
    }

    return {
      validRXPackages: filteredPackages,
      invalidRXPackages: greyOutFilteredPackages,
    };
  };
  const enableNestedTransitionSwatch = useSelector(
    (state: any) => state.config?.layoutSettings?.enableNestedTransitionSwatch
  );

  const filterTreatmentsByFamily = (
    treatments: any[],
    required: string[],
    additional?: string[]
  ) => {
    let toFilterData = [];
    if (required) {
      toFilterData = [...toFilterData, ...required];
    }
    if (additional) {
      toFilterData = [...toFilterData, ...additional];
    }
    if (treatments) {
      return treatments.filter((t) => {
        const key = t.id.split("$")[0];
        return toFilterData.some((f) => f === key);
      });
    }
    return null;
  };

  const onClickTreatment = (obj: {
    id: string;
    title: string;
    price: number;
    discount: number;
    insPrice: number;
    color?: any;
  }) => {
    let hasSelectedColorTreatment = false;

    if (obj?.color) {
      hasSelectedColorTreatment = true;
    }

    const idT = obj.id.split("$")[0];
    const valueT = obj.id.split("$")[1];
    const mapped = treatmentAvaiable.map((ta: any) => {
      const idTa = ta.id.split("$")[0];
      const valueTa = ta.id.split("$")[1];
      if (idTa === idT) {
        if (valueTa === valueT) {
          ta.isSelected = !ta.isSelected;
        } else {
          ta.isSelected = false;
        }
      }
      return ta;
    });
    setTreatmentAvaiable(mapped);
    setTreatments(mapped);
    //to reload price
    let onlySelected = [
      ...mapped
        .filter((ta: any) => ta.isSelected)
        .map((ta: any) => {
          let ret = new ParameterWithTitle();
          const idTa = ta.id.split("$")[0];
          const valueTa = ta.id.split("$")[1];
          ret.key = idTa;
          ret.value = valueTa;
          ret.title = obj.title;
          ret.price = obj.price;
          ret.discount = obj.discount;
          ret.insPrice = obj.insPrice;
          return ret;
        }),
      {
        key: "photocromic",
        value: isPhotocromic,
      },
    ];

    dispatch(workflowActions.setParameterPrice(onlySelected));

    const familyFilter = getFamilyFilter();

    if (obj?.color) {
      const colorPrice = c(
        { color: obj.color.id },
        currentPackages.validRXPackages.filter(
          (p) => p.lensPackage.transition === valueT
        )
      );

      let truthValue = false;

      Object.keys(colorPrice).forEach((e) => {
        truthValue =
          truthValue || (colorPrice[e] !== 0 && colorPrice[e] !== null);
      });

      let spread = truthValue ? colorPrice : {};

      let params = [
        ...onlySelected,
        {
          key: "treatments",
          value: obj.title,
          title: obj.title,
          price: obj.price,
          discount: obj.discount,
          insPrice: obj.insPrice,
          originalStep: "Treatments",
        },
        {
          key: "color",
          value: obj?.color?.id,
          ...spread,
        },
        {
          key: "transitionColor",
          value: obj.color.id,
          ...spread,
        },
      ];

      if (designTypesAvaiable && enableDesignTypeStep === true) {
        workflow.goToStep("DesignType", params);
      } else {
        workflow.goToStep("Thickness", params);
      }
    } else if (familyFilter && familyFilter.value?.nextStep) {
      if (obj?.color) {
        const colorPrice = c(
          { color: obj.color.id },
          currentPackages.validRXPackages.filter(
            (p) => p.lensPackage.transition === valueT
          )
        );

        let truthValue = false;

        Object.keys(colorPrice).forEach((e) => {
          truthValue =
            truthValue || (colorPrice[e] !== 0 && colorPrice[e] !== null);
        });
        let spread = truthValue ? colorPrice : {};
        let params = [
          ...onlySelected,
          {
            key: "treatments",
            value: obj.title,
            title: obj.title,
            price: obj.price,
            discount: obj.discount,
            insPrice: obj.insPrice,
            originalStep: "Treatments",
          },
          {
            key: "color",
            value: obj?.color?.id,
            ...spread,
          },
          {
            key: "transitionColor",
            value: obj?.color?.id,
            ...spread,
          },
        ];
        workflow.goToStep("Thickness", params);
      } else if (
        hasSelectedColorTreatment &&
        familyFilter &&
        familyFilter.value?.nextStep
      ) {
        workflow.goToStep(familyFilter.value.nextStep, onlySelected);
      } else {
        let params = [
          ...onlySelected,
          {
            key: "treatments",
            value: obj.title,
            title: obj.title,
            price: obj.price,
            discount: obj.discount,
            insPrice: obj.insPrice,
            originalStep: "Treatments",
          },
        ];
        if (enableNestedTransitionSwatch) {
          workflow.goToStep("Thickness", params);
        } else {
          workflow.goToStep("TransitionColor", params);
        }
      }
    }
  };

  const nextOrSkip = (
    selected: any,
    skipped?: boolean,
    isPreselected?: boolean
  ) => {
    if (!skipped) {
      onClickTreatment(selected);
    } else {
      if (!isPhotocromic) {
        const familyFilter = getFamilyFilter();
        if (familyFilter) {
          if (familyFilter.value?.nextStep) {
            let prevTreatmentFamilyParams = workflow
              .getPrevCurrentParameterStep()
              .filter((param) => {
                return param.key === "treatmentsFamily";
              });

            let treatmentToSet = new ParameterWithTitle();
            treatmentToSet.key = familyFilter.value.required[0];

            let filtered = included.filter((tIncluded) => {
              return tIncluded.id === familyFilter.value.required[0];
            });
            if (filtered.length) {
              treatmentToSet.value = filtered[0].key;
            }

            treatmentToSet.skipped = true;
            dispatch(workflowActions.setParameterPrice([treatmentToSet]));

            workflow.goToStep(
              familyFilter.value?.nextStep,
              [
                {
                  key: prevTreatmentFamilyParams.length
                    ? "treatmentsFamily"
                    : "treatment",
                  value: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].value
                    : selected,
                  price: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].price
                    : null,
                  discount: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].discount
                    : null,
                  insPrice: prevTreatmentFamilyParams.length
                    ? prevTreatmentFamilyParams[0].insPrice
                    : null,
                },
              ],
              true
            );
          } else {
            workflow.goToStep("Thickness", [], true);
          }
        } else {
          if (!selected) {
            workflow.goNextStep([], true);
          }
        }
      } else if (isPhotocromic && isPreselected) {
        onClickTreatment(selected);
      }
    }
  };

  const getKey = (title: string) =>
    "Treatments_sc_" + title.split(" ").join("_");

  const selectTreatmentWithColor = (c, color) => {
    setShowConfirmButton(true);
    setSelectedTreatment({ ...c, color: color });
    const originalTreatment = treatments?.find((t) => t.id === c.id);
    setTreatmentAvaiable((treatments) =>
      treatments.map((t) => {
        if (t.id === c.id) {
          return {
            ...t,
            price: color.price + originalTreatment.price,
            discount: color.discount + originalTreatment.discount,
          };
        } else {
          return t;
        }
      })
    );
    if (isInsuranceEnabled) {
      let filteredPackages = currentPackages.validRXPackages.filter((p) => {
        return (
          p.lensPackage.color === color.id &&
          p.lensPackage.transition === c.id.split("$")[1]
        );
      });
      if (!filteredPackages.length) return;

      // let cheapestPackages = null;
      // if (filteredPackages.length > 1) {
      //   cheapestPackages = [getCheapPackageFromList(filteredPackages)];
      // } else {
      //   cheapestPackages = filteredPackages;
      // }

      let cheapestPackages = filteredPackages;

      let insurablePackages = cheapestPackages.filter(
        (pkg) => pkg.lensPackage.insurable
      );
      if (insurablePackages.length) {
        callInsuranceService(
          cheapestPackages,
          configInsurance,
          frame,
          dispatch
        );
      }
    }
  };

  useEffect(() => {
    // if there is only one treatment click it
    if (treatmentAvaiable && treatmentAvaiable.length === 1) {
      nextOrSkip(treatmentAvaiable[0], true);
    }
  }, [treatmentAvaiable]);

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.treatments.title"),
        subtitle:
          brand !== "oakley" &&
          brand !== "osi" && brand !== "clearly" &&
          translate("steps.treatments.subtitle"),
        showGrayOutBanner:
          treatmentAvaiable && treatmentAvaiable.find((t) => t.disabled),
      }}
    >
      {learnMoreButton !== "steps.treatments.contentIdentifier" &&
        learnMoreButton !== undefined &&
        learnMoreButton !== null &&
        delegatingLearnMore &&
        delegateLearnMoreContent && (
          <a
            className="learnMoreButton"
            onClick={() => delegateLearnMoreContent(learnMoreButton)}
            data-element-id={
              (currentType === `SUNGLASSES` ? `SunLensPanel` : `LensPanel`) +
              "_LensTreatment-LearnMore"
            }
          >
            {moreInfoLabel !== "" &&
              moreInfoLabel !== undefined &&
              moreInfoLabel !== null &&
              moreInfoLabel !== "steps.treatments.learnMoreCTA"
              ? moreInfoLabel
              : "More information"}
          </a>
        )}
      <div className={"Treatmentsfamily__container"}>
        <BrandComponent
          componentName="SkipContainer"
          parameter={{
            stepName: "Treatments",
            onNext: nextOrSkip,
            parameterPreselection: ["blueLight", "transition", "uvProtection"],
            forceRender: isPhotocromic,
            onPreselect: (selected, skipped: boolean) => {
              let selectedT = null;
              let selectedB = false;
              treatmentAvaiable.forEach((t) => {
                const id = t.id.split("$")[0];
                const val = t.id.split("$")[1];
                if (selected[id] === val && !selectedB) {
                  selectedT = t;
                  selectedB = true;
                }
              });

              nextOrSkip(selectedT, skipped, true);
            },
          }}
        >
          {treatmentAvaiable &&
            treatmentAvaiable.map((c: any) => (
              <BrandComponent
                key={getKey(c.title) || "MISSING"}
                componentName="StepCard"
                parameter={{
                  ...c,
                  title: c.title || "MISSING",
                  description: c.description || "MISSING",
                  badge: c.textBadge
                    ? getStepBadgeType(c.textBadge)
                    : c.badge
                      ? getStepBadgeType(c.badge)
                      : null,
                  disabled:
                    c.badge == "coming soon" || c.textBadge == "coming soon"
                      ? true
                      : false,
                  onClick:
                    c.badge == "coming soon" ||
                      c.disabled ||
                      c.textBadge == "coming soon"
                      ? null
                      : (color) => {
                        if (!color?.id) {
                          onClickTreatment(c);
                        } else {
                          selectTreatmentWithColor(c, color);
                        }
                      },
                  selected: c.id === selectedTreatment?.id,
                  fadeShadow3DIcon: true,
                  hideSign:
                    brand === "oakley" ||
                      brand === "osi" ||
                      (brand === "targetotpical" &&
                        treatmentAvaiable.price !== 0) ||
                      brand === "rayban" ||
                      brand === "oliverpeoples" ||
                      brand === "persol"
                      ? false
                      : true,
                }}
              />
            ))}
        </BrandComponent>
        {showConfirmButton && selectedTreatment && (
          <div className="confirmButton__container">
            <GenericButton
              handleClick={() => onClickTreatment(selectedTreatment)}
              className="confirmButton"
              noArrow
            >
              <span>{translate("confirmButton")}</span>
            </GenericButton>
          </div>
        )}
      </div>
      <ReferencedSection name={"ScrollView__" + workflow?.currentStep?.key} />
    </BrandComponent>
  );
}
