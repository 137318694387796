import React, { useEffect, useMemo, useRef, useState } from "react";
import "./default.module.scss";
import Image from "../common/Image";
import { useDispatch, useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import {
  useTranslate,
  useTranslation,
} from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import {
  getChipsList,
  getCorrectAnalyticsStepContainerName,
} from "../../configurator/ConfigLoader";
import { StepBadgeType } from "../StepBadge/types/StepBadgeType";
import { workflowActions } from "../../redux/slices/workflow";
import { AutomaticDiscountParameter } from "../../configurator/models/AutomaticDiscountParameter";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { isDesktop, isMobile } from "react-device-detect";
import {
  useCurrentPackages,
  usePriceDiscountBadge,
  useLargeIcons,
  useTransitionColors,
  useTilesFeatureListLayout,
  useLearnMore,
  useMixAndMatch,
} from "@hooks";
import { isFunction } from "lodash";
import { colorActions } from "../../redux/slices/color";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";

export interface StepCardProps {
  title: string;
  description?: string;
  price?: number;
  discount?: number;
  insPrice?: number;
  onClick?: (p: any) => void;
  badge?: StepBadgeType;
  textBadge?: string;
  imageUrl?: string;
  id: string;
  noMarginTitle?: boolean;
  included?: boolean;
  noImageCard?: boolean;
  moreInfo?: Function;
  showPresriptionButton?: boolean;
  showPresriptionButtonFunction?: Function;
  sequence?: number;
  disabled?: boolean;
  discountLoading?: boolean;
  contentIdentifier?: string;
  brand: string;
  transitionId?: string;
  noMobileSpacer?: boolean;
  ignoreInsurance?: boolean;
  moreInfoTitle?: string;
  selected?: boolean;
  fadeShadow3DIcon?: boolean;
  hideSign?: boolean;
  ignorePriceLabels?: boolean;
  showSavingsLabel?: boolean;
}

export function StepCard({
  title,
  description,
  price,
  discount,
  insPrice,
  badge,
  textBadge,
  onClick,
  imageUrl,
  id,
  included,
  showPresriptionButton = false,
  showPresriptionButtonFunction = () => { },
  noImageCard,
  moreInfo,
  disabled,
  discountLoading,
  contentIdentifier,
  brand,
  transitionId, // to handle custom transition id
  sequence,
  noMobileSpacer,
  ignoreInsurance,
  moreInfoTitle,
  selected,
  fadeShadow3DIcon,
  hideSign,
  ignorePriceLabels,
  showSavingsLabel,
}: StepCardProps) {
  const currentPackages = useCurrentPackages();
  const currentStep = useSelector(
    (state: any) => state?.workflow?.currentStep?.key
  );
  const { learnMoreVisible, delegateLearnMoreCheck, getMoreInfoTitle } =
    useLearnMore(currentStep, contentIdentifier);
  const { isLargeIconsActive, getIcons } = useLargeIcons();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [apngLoaded, setApngLoaded] = useState(false);
  const [isColorSelected, setIsColorSelected] = useState(false);
  const isMixAndMatchStep = useMixAndMatch();
  let promoBadgeID = id;
  let splittedID = promoBadgeID.split("$");
  if (splittedID.length > 1) {
    promoBadgeID = splittedID[1];
  } else {
    promoBadgeID = splittedID[0];
  }
  const tilesLayout = useTilesFeatureListLayout();
  const promoBadge = usePriceDiscountBadge(promoBadgeID, discount, price);
  const configProjectImage = useSelector(
    (state: any) => state.config.baseURLs.projectImage
  );

  const { colors, selectColor, selectedColor } = useTransitionColors(id);

  const stepCardButtonRef = useRef(null);

  const autoDiscountBadge = useSelector(
    (state: any) => state.config?.data?.autoDiscountBadge
  );

  //OO
  useEffect(() => {
    const isFirstCard = sequence;

    if (selectedColor && currentStep === "Treatments") {
      const colorSequence = selectedColor.sequence;

      if (isFirstCard === 0 || colorSequence === 0) {
        selectColor(selectedColor);
        stepCardButtonRef.current.focus();
        stepCardButtonRef.current.click();
      }
    }
  }, [selectedColor]);

  //OPSM
  useEffect(() => {
    if (colors && selectedColor && selectColor && currentStep === "TreatmentsFamily") {
      if (isColorSelected) {
        selectColor(selectedColor);
        stepCardButtonRef.current.focus();
        stepCardButtonRef.current.click();
      }
    }
  }, [selectedColor, colors]);


  const largeIconBasepath = useSelector(
    (state: any) =>
      `${state.config.baseURLs.assetsCDN || ""}/extra/image/rxc/icons/`
  );
  const showBundlePrice = useSelector(
    (state: any) => state?.config?.layoutSettings?.showBundlePrice
  );
  const translation = useTranslation();
  const dispatch = useDispatch();
  const [chips, setChips] = useState("");
  var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );

  const onCardClick = (e) => {
    let currentStepName = workflow.currentStep?.key;
    e.target.focus();
    let isClickable =
      currentStepName === "ProtectionPlan"
        ? true
        : (insuranceEnabled &&
          (price === undefined || price === null || isNaN(price))) ||
        (insPrice !== undefined && insPrice !== null && !isNaN(insPrice));

    if (!insuranceEnabled || isNonPrescriptionAvailable() || isClickable) {
      if (onClick) {
        if (promoBadge) {
          const currentStep = workflow.currentStep;
          const param = new AutomaticDiscountParameter(
            promoBadge.key,
            promoBadge.value,
            {
              key: currentStep.key,
              progressive: currentStep.progressive,
            }
          );
          dispatch(
            workflowActions.setAutomaticDiscountBadgeStepSelection(param)
          );
        }
        if (colors && selectedColor && selectColor) {
          onClick(selectedColor);
          selectColor(selectedColor);
        } else {
          onClick(id);
        }
      }
    }
  };

  const getDataElementIdForCard = () => {
    let currentStepName = currentStep;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);

    switch (id) {
      case "uploadPrescriptionCard":
        return "X_X_Verification_Upload";
      case "doctorPrescriptionCard":
        return "X_X_Verification_CallDoctor";
      case "manualPrescriptionCard":
        return "X_X_Verification_Manually";
      default:
        return "X_X_LensPanel_" + analyticsStepName + "-Choose";
    }
  };

  const getDataElementIdForLearnMore = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-LearnMoreOpen";
  };

  const isNonPrescriptionAvailable = () => {
    return (
      !currentPackages ||
      currentPackages.find((pkg) => pkg.lensPackage.type === "Non_prescription")
    );
  };

  const isBadgeVisible = () => {
    return (
      badge &&
      (!promoBadge ||
        (promoBadge &&
          price !== undefined &&
          price !== null &&
          discount !== undefined &&
          discount !== null))
    );
  };

  const isTopPromoBadgeVisible = () => {
    return (
      promoBadge &&
      (price === undefined || price === null) &&
      (discount === undefined || discount === null)
    );
  };
  const fileNameImages = useMemo(() => {
    return getIcons(transitionId || id);
  }, [id, translation]);

  const TransitionColors = () => {
    if (!colors) return null;
    if (!title.toLowerCase().includes("transition")) return null;
    return (
      <div className="StepCard__layout__transitionColors">
        <div className="selectedColor">
          <p>
            {translation("steps.color.confirmedTitle")}{" "}
            <span>{selectedColor?.title}</span>
          </p>
        </div>
        <div className="colors">
          {colors.map((color) => {
            return (
              <a
                tabIndex={0}
                // data-element-id={getDataElementIdForCard()}
                data-description={color.title}
                data-vmmv={color.title + id}
                key={"color_" + color.id}
                className={
                  "color" +
                  (selectedColor?.id === color.id && selected
                    ? "  selected"
                    : "") +
                  (color.disabled ? " disabled" : "")
                }
                onClick={
                  color.disabled
                    ? () => null
                    : (e) => {
                      e.stopPropagation();
                      stepCardButtonRef?.current?.click();
                      dispatch(colorActions.selectColor(color));
                      selectColor(color);
                      setApngLoaded(false);
                      setIsColorSelected(true);
                      // è brutto ma funziona per mantenere in sync il colore selezionato
                      setTimeout(() => {
                        e.stopPropagation();
                        stepCardButtonRef?.current?.click();
                        dispatch(colorActions.selectColor(color));
                        selectColor(color);
                        setApngLoaded(false);
                      }, 100);
                    }
                }
              >
                <Image
                  imageClass="color__img"
                  url={configProjectImage}
                  fileName={color?.imageFileName}
                // crossOrigin={"enabled"}
                />
              </a>
            );
          })}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (description) {
      let ch = getChipsList(description);
      if (ch) {
        setChips("<ul>" + ch[1]);
      }
    }
  }, [description]);

  const onApngLoaded = () => {
    setApngLoaded(true);
  };
  const onPngLoaded = () => {
    document.documentElement.classList.remove("showSkeleton");
  };
  const currentStepKey = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );

  return (
    <div
      className={"StepCard__container"}
      onClick={onCardClick}
      data-element-id={getDataElementIdForCard()}
      data-description={title}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <button
        className={"StepCard__card" + (disabled ? "_disabled" : "")}
        // style={{ height: brand === "framesdirect" ? "85%" : "100%" }}
        data-element-id={
          currentStep?.toLowerCase() === "protectionplan"
            ? getDataElementIdForCard()
            : null
        }
        data-description={
          currentStep?.toLowerCase() === "protectionplan" ? title : null
        }
        ref={stepCardButtonRef}
      >
        <div className={"StepCard__layout"}>
          <div
            style={{
              display: currentStepKey === "AdvancedPrescription" ? "" : "flex",
            }}
          >
            {disabled && <div className="StepCard__layout__disabled" />}
            {!isLargeIconsActive ? (
              imageUrl &&
              !isMobile && (
                <div
                  className={`StepCard__layout__imgcontainer ${noImageCard ? "noImage" : ""
                    }`}
                >
                  <Image
                    imageClass={`StepCard__layout__imgcontainer__img`}
                    url={configProjectImage}
                    fileName={imageUrl}
                    alt={imageUrl}
                  />
                </div>
              )
            ) : (
              //Large Icons
              <div
                // style={
                //   !isHTML(description)
                //     ? { overflowX: "hidden", borderRadius: "8px" }
                //     : { overflowX: "visible" }
                // }
                className={`StepCard__layout__largeimgcontainer ${noImageCard ? "noImage" : ""
                  } ${fadeShadow3DIcon ? " fade-shadow" : ""}`}
              >
                <>
                  <Image
                    imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                    url={largeIconBasepath}
                    fileName={
                      selectedColor?.imageFileName1 ||
                      fileNameImages?.[0] ||
                      imageUrl
                    }
                    alt={imageUrl}
                    style={{
                      display:
                        isMouseOver || (apngLoaded && isMobile)
                          ? "none"
                          : "inherit",
                    }}
                    onLoad={onPngLoaded}
                  />
                  {fileNameImages?.length === 2 && (
                    <Image
                      imageClass={`StepCard__layout__largeimgcontainer__largeimg`}
                      url={largeIconBasepath}
                      fileName={
                        selectedColor?.imageFileName2
                          ? `${selectedColor?.imageFileName2}.apng`
                          : fileNameImages[1]
                            ? fileNameImages[1]
                            : imageUrl
                      }
                      alt={imageUrl}
                      style={{
                        display:
                          !apngLoaded || (!isMouseOver && isDesktop)
                            ? "none"
                            : "inherit",
                      }}
                      onLoad={onApngLoaded}
                    />
                  )}
                </>
              </div>
            )}
            {/* {brand === "rayban" && !imageUrl && !isMobile && (
              <div
                className={
                  "StepCard__layout__imgcontainer" +
                  " " +
                  (noImageCard ? "noImage" : "")
                }
              >
                <Image
                  imageClass="StepCard__layout__imgcontainer__img"
                  url={
                    "https://media.costadelmar.com/images/RXconfigurator/blue-light.png"
                  }
                  fileName={
                    "https://media.costadelmar.com/images/RXconfigurator/blue-light.png"
                  }
                  alt={"fallback"}
                />
              </div>
            )} */}
            {isBadgeVisible() && (
              <BrandComponent
                componentName="StepBadge"
                parameter={{ type: badge, text: textBadge }}
              />
            )}
            {isTopPromoBadgeVisible() && (
              <div
                className="StepCard__layout__topBadgeWrapper"
                style={{
                  display:
                    autoDiscountBadge && promoBadgeID === "Frame Only"
                      ? "none"
                      : "",
                }}
              >
                <BrandComponent
                  componentName="StepPriceBadge"
                  parameter={{
                    badge:
                      autoDiscountBadge && promoBadgeID === "Frame Only"
                        ? ""
                        : promoBadge?.badge,
                  }}
                />
              </div>
            )}
            <div
              className={
                brand !== "rayban" || !imageUrl
                  ? "StepCard__layout__content spacer"
                  : "StepCard__layout__content"
              }
            >
              <div
                className={
                  badge
                    ? "StepCard__layout__content__title__badgespacer"
                    : "StepCard__layout__content__title"
                }
              >
                {title}
              </div>
              <div className={"StepCard__layout__content__description"}>
                {!chips.includes("<ul>") && tilesLayout !== "CHIPS" ? (
                  <p
                    className={
                      "StepCard__layout__content__description__" +
                      (tilesLayout === "CHIPS" ? "chips" : "text")
                    }
                    style={{ marginBottom: 5 }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                ) : (
                  <p
                    className={
                      "StepCard__layout__content__description__" +
                      (tilesLayout === "CHIPS" ? "chips" : "text")
                    }
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                )}
              </div>
              {learnMoreVisible() && (
                <a
                  className={"StepCard__layout__content__learnMore"}
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    delegateLearnMoreCheck();
                  }}
                  data-element-id={getDataElementIdForLearnMore()}
                >
                  {moreInfoTitle
                    ? translation(moreInfoTitle)
                    : getMoreInfoTitle()}
                </a>
              )}
              {TransitionColors() && brand === "rayban" && isDesktop && (
                <>
                  <div
                    className="StepCard__layout__content__spacer"
                    style={{ marginBottom: "0px" }}
                  ></div>
                </>
              )}
              {isDesktop && TransitionColors()}
              {disabled && isDesktop && (
                <>
                  <div className="StepCard__layout__content__spacer"></div>
                  <p id="notAvailable">{translation("notAvailable")}</p>
                </>
              )}
              {brand === "arnette" && showPresriptionButton && (
                <>
                  <div className="StepCard__layout__prescriptionButton--separator" />
                  <div className="StepCard__layout__prescriptionButton--btn-container">
                    <a
                      className="StepCard__layout__prescriptionButton__btn-style"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        showPresriptionButtonFunction();
                      }}
                    >
                      <span>{translation("steps.type.addToCart")}</span>
                    </a>
                  </div>
                </>
              )}
              {!moreInfo && (price === undefined || price === null) ? null : (
                <>
                  {!isMobile && (
                    <div
                      className={"StepCard__layout__content__spacer"}
                      style={learnMoreVisible() ? { marginTop: "0px" } : {}}
                    />
                  )}
                  {!isMobile && (
                    <div
                      className={
                        "StepCard__layout__content__learnMorePriceContainer" +
                        (!showSavingsLabel ? " onlyPrice" : "")
                      }
                    >
                      {/* {learnMoreVisible() && (
                        <a
                          className={"StepCard__layout__content__learnMore"}
                          tabIndex={0}
                          onClick={(e) => {
                            e.stopPropagation();
                            delegateLearnMoreCheck();
                          }}
                          data-element-id={getDataElementIdForLearnMore()}
                        >
                          {moreInfoTitle
                            ? translation(moreInfoTitle)
                            : getMoreInfoTitle()}
                        </a>
                      )} */}
                      {showSavingsLabel && (
                        <BrandComponent
                          componentName="Savings"
                          parameter={{ discount: discount }}
                        ></BrandComponent>
                      )}
                      {price != null ? (
                        <div className={"StepCard__layout__content__price"}>
                          {!included ? (
                            <BrandComponent
                              componentName="StepPrice"
                              parameter={{
                                price,
                                discount,
                                insPrice,
                                promo: promoBadge?.badge,
                                ignoreInsurance:
                                  isNonPrescriptionAvailable() ||
                                  ignoreInsurance,
                                discountLoading,
                                hideSign: !hideSign ? false : true,
                                ignorePriceLabels: ignorePriceLabels,
                                strikePriceStyle: showBundlePrice
                                  ? { display: currentStep === "LensBundle" ? "inline-block" : "none" }
                                  : {},
                              }}
                            />
                          ) : (
                            translation("price.included")
                          )}
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {chips.includes("<ul>") && tilesLayout === "CHIPS" && (
            <div
              className="StepCard__layout__mobileChips"
              dangerouslySetInnerHTML={{ __html: chips }}
            />
          )}
          {TransitionColors() && brand === "rayban" && isMobile && (
            <>
              <div
                className="StepCard__layout__content__spacerMobile"
                style={{ marginBottom: "16px" }}
              ></div>
            </>
          )}
          {isMobile && TransitionColors()}
          {isMobile && selectedColor?.clen ? (
            <BrandComponent
              componentName="VirtualMirrorButton"
              parameter={{
                onClick: () => {
                  dispatch(virtualMirrorActions.setShowVirtualMirror(true));
                },
              }}
            />
          ) : null}
          {isMobile &&
            (included || !isNaN(price)) &&
            noMobileSpacer !== false && (
              <div className={"StepCard__layout__content__spacerMobile"} />
            )}
          {isMobile && (
            <div
              style={{
                padding:
                  price != null
                    ? !showSavingsLabel
                      ? `0px 16px 8px ${currentStep === "ProtectionPlan" ? "32px" : "16px"
                      }`
                      : `0px 5px 8px`
                    : ``,
              }}
              className={
                "StepCard__layout__content__learnMorePriceContainer" +
                (!learnMoreVisible() && !showSavingsLabel ? " onlyPrice" : "") +
                (!learnMoreVisible() &&
                  !price &&
                  currentStep === "ProtectionPlan"
                  ? " rxcHide"
                  : "")
              }
            >
              {/* {learnMoreVisible() && (
                <a
                  className={"StepCard__layout__content__learnMore"}
                  tabIndex={0}
                  onClick={(e) => {
                    e.stopPropagation();
                    delegateLearnMoreCheck();
                  }}
                  data-element-id={getDataElementIdForLearnMore()}
                >
                  {moreInfoTitle
                    ? translation(moreInfoTitle)
                    : getMoreInfoTitle()}
                </a>
              )} */}
              {showSavingsLabel && (
                <BrandComponent
                  componentName="Savings"
                  parameter={{ discount: discount }}
                ></BrandComponent>
              )}
              {price != null ? (
                <div className={"StepCard__layout__content__price"}>
                  {!included ? (
                    <BrandComponent
                      componentName="StepPrice"
                      parameter={{
                        price,
                        discount,
                        insPrice,
                        promo: promoBadge?.badge,
                        ignoreInsurance:
                          isNonPrescriptionAvailable() || ignoreInsurance,
                        discountLoading,
                        hideSign: !hideSign ? false : true,
                        ignorePriceLabels: ignorePriceLabels,
                      }}
                    />
                  ) : (
                    translation("price.included")
                  )}
                </div>
              ) : null}
            </div>
          )}
          {disabled && isMobile && (
            <>
              <div className="StepCard__layout__content__spacer"></div>
              <p id="notAvailable">{translation("notAvailable")}</p>
            </>
          )}
        </div>
      </button>
    </div>
  );
}
